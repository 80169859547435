import { useRecoilState } from 'recoil';
import ChangePassword from './ChangePassword';
import UserList from './UserList';
import { authState, Role } from '../../state/auth';
import CompanyList from './CompanyList';

const Settings = (props: any) => {
    const [auth, _setAuth] = useRecoilState(authState);

    return <>
        <UserList />
        <ChangePassword />
        {
            auth.user?.role === Role.appAdmin ? <CompanyList /> : null
        }
    </>
};

export default Settings;