import { Context, createContext, useContext } from "react";
import { CreateProjectRequest } from "../api/requests/project";
import { ProfileRecordChange, SymbolsFile } from "../types/profile";
import { Project, ProjectFileLineChange, RawProjectRecordChange, SelectedFile } from "../types/project";
import { Role } from "./auth";

export interface IAPIStateContext {
    refreshProfiles: any,
    refreshProjects: any,
    deleteProject: any,
    updateProject: any,
    copyProfile: any,
    createProject: any,
    createProfile: any,
    deleteProfile: any,
    getProfileRecords: any,
    updateProfileRecords: any,
    updateProfileHeader: any,
    downloadFile: any,
    loadProject: any,
    updateProjectFileLines: any,
    updateRawProjectRecords: any,
    getProjectRawRecords: any,
    getProjectRecords: any,
    recalculateConversions: any,
    initiateDrawing: any,
    getDrawingStatus: any,
    listUsers: any,
    deleteUser: any,
    getUser: any,
    updateUser: any,
    createUser: any,
    updateUserPassword: any,
    contact: any,
    listCompanies: any,
    activateCompany: any
    deleteCompany: any
};

export const APIStateContext: Context<IAPIStateContext> = createContext({
    refreshProfiles: async () => {},
    refreshProjects: async () => {},
    deleteProject: async (projectId: string) => {},
    updateProject: async (project: Project) => {},
    copyProfile: async (profileId: string, newName: string) => {},
    createProject: async (createProjectRequest: CreateProjectRequest) => {},
    createProfile: async (name: string, objFile: SelectedFile, dwgFile: SelectedFile) => {},
    deleteProfile: async (id: string) => {},
    getProfileRecords: async (profileID: string) => {},
    updateProfileRecords: async (changes: Array<ProfileRecordChange>, profileID: string) => {},
    updateProfileHeader: async (profileID: string, name: string, symbolsFile: SymbolsFile, objFile: SelectedFile) => {},
    downloadFile: async (name: string, url: string) => {},
    loadProject: async(projectId: string) => {},
    updateProjectFileLines: async(projectId: string, changes: Array<ProjectFileLineChange>) => {},
    updateRawProjectRecords: async(projectId: string, changes: Array<RawProjectRecordChange>) => {},
    getProjectRawRecords: async(projectId: string, pageNumber: number, pageSize: number) => {},
    getProjectRecords: async(projectId: string, pageNumber: number, pageSize: number) => {},
    recalculateConversions: async (projectId: string, pointNumbers: Array<Array<string>>) => {},
    initiateDrawing: async (projectId: string, preview: boolean) => {},
    getDrawingStatus: async (projectId: string) => {},
    listUsers: async () => {},
    deleteUser: async (userId: string) => {},
    getUser: async (userId: string) => {},
    updateUser: async (userId: string, name: string, email: string, role: Role) => {},
    createUser: async (name: string, email: string, role: Role) => {},
    updateUserPassword: async (password: string) => {},
    contact: async (message: string) => {},
    listCompanies: async () => {},
    activateCompany: async (companyId: string) => {},
    deleteCompany: async (companyId: string) => {}
});

export function useAPI() {
    return useContext(APIStateContext);
};