import { useEffect, useState } from "react";
import { useLabel, useLanguage } from "../../hooks"
import { Company } from "../../types/auth";
import { useAPI } from "../../state/context";
import ApartmentIcon from '@mui/icons-material/Apartment';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListCompaniesResponse } from "../../api/responses/company";
import { Box, Button, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { ConfirmationDialog } from "../../components";

interface CompanyListProps {}

function CopyButton(props: {text: string}) {
    const language = useLanguage();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 3000);
        }
    }, [copied])

    return <Tooltip title={copied ? language.company.copied : language.company.copy}>
        <IconButton
            onClick={() => {
                navigator.clipboard.writeText(props.text);
                setCopied(true);
            }}
        >
            <ContentCopyIcon />
        </IconButton>
        </Tooltip>
    }


export default function CompanyList(props: CompanyListProps) {
    const language = useLanguage();
    const [companies, setCompanies] = useState<Company[]>([]);
    const api = useAPI();

    function refreshCompanies() {
        api.listCompanies().then((response: ListCompaniesResponse) => setCompanies(response.companies))
    }

    function activateCompany(companyId: string) {
        api.activateCompany(companyId).then(refreshCompanies)
    }

    useEffect(refreshCompanies, [])

    const DeleteIconButton = (props: {onDelete: any}) => {
        const tooltip = useLabel("labels.delete");
        const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    
        return <>
            <Tooltip title={tooltip}>
                <Button onClick={(event: any) => {event.stopPropagation(); setOpenDeleteConfirmation(true);}} 
                    variant="outlined"
                    sx={{ padding: 1, minWidth: 0, borderRadius: "24px", marginLeft: 1}}
                    color="error"
                >
                    <DeleteIcon fontSize="small" />
                </Button> 
            </Tooltip>
            <ConfirmationDialog
                open={openDeleteConfirmation}
                setOpen={setOpenDeleteConfirmation}
                onConfirm={props.onDelete}
                textLabelKey="labels.confirmDelete"
            />
        </>
    };

    const CompanyRow = (props: { company: Company }) => {
    
        return <TableRow hover onClick={(event: any) => {

        }} sx={{ cursor: "pointer"}}>
            <TableCell><ApartmentIcon sx={{ color: "lightgrey" }}/></TableCell>
            <TableCell>{props.company.name}</TableCell>
            <TableCell>
                <Stack direction={"row"} sx={{width: "100%", justifyContent: "space-between"}}>
                    <p>{props.company.id}</p>
                    <CopyButton text={props.company.id}/>
                </Stack>
            </TableCell>
            <TableCell>
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    {props.company.active ? <CheckCircleOutline color="success"/> : <CancelOutlined color="error" />}
                </div>
            </TableCell>
            <TableCell>{props.company.free_trial ? language.company.freeTrial : language.company.professional}</TableCell>
            <TableCell>
                <Button variant="contained" onClick={() => activateCompany(props.company.id)}>{language.company.activate}</Button>
                <DeleteIconButton onDelete={() => api.deleteCompany(props.company.id).then(refreshCompanies)}/>
            </TableCell>
        </TableRow>
    };

    const TableItems = (props: {companies: Company[]}) => {
        return <TableBody>{props.companies.map((company: Company) => <CompanyRow company={company} key={company.id} />)}</TableBody>
    };

    const TableHeader = () => {

        return <TableHead>
            <TableRow>
                <TableCell></TableCell>
                <TableCell>{language.company.name}</TableCell>
                <TableCell>{language.company.identifier}</TableCell>
                <TableCell>{language.company.active}</TableCell>
                <TableCell>{language.company.subscription}</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    };

    return <Box m={4}>
        <h1>{language.company.companies}</h1>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: "80%" }} >
                <TableHeader />
                <TableItems companies={companies}/>
            </Table>
        </TableContainer>
    </Box>
}