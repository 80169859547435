import { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import client from "../api";
import { selectToken, selectCompany, authState, selectUser, Role } from "./auth";
import { DeleteProfileRequest, GetProfilesRequest, GetProfileRecordsRequest, UpdateProfileRecordsRequest, UpdateProfileHeaderRequest, CopyProfileRequest, CreateProfileRequest } from "../api/requests/profile";
import { GetProfilesResponse } from "../api/responses/profile";
import { profileState } from "./profile";
import { appInfoState } from "./info";
import AppInfoResponse from "../api/responses/appInfo";
import { activeProjectState, projectListState } from "./project";
import { CreateProjectResponse, DeleteProjectResponse, DrawingStatus, GetProjectsResponse } from "../api/responses/project";
import { APIStateContext } from "./context";
import { CreateProjectRequest, DeleteProjectRequest, DrawRequest, GetDrawingStatusRequest, GetRecordsRequest, LoadActiveProjectRequest, RecalculateConversionsRequest, UpdateProjectFileLinesRequest, UpdateProjectRecordsRequest, UpdateProjectRequest } from "../api/requests/project";
import { DrawSettings, ParseResult, Project, ProjectFileLineChange, RawProjectRecordChange, SelectedFile } from "../types/project";
import { ProfileHeader, ProfileRecordChange, SymbolsFile } from "../types/profile";
import { DownloadFileRequest } from "../api/requests/common";
import { APIException, ParseErrorType } from "../api/errors/errors";
import { useLanguage } from "../hooks/language";
import { Alert, Backdrop, CircularProgress, LinearProgress, Snackbar } from "@mui/material";
import { Language } from "../language";
import { CreateUserRequest, DeleteUserRequest, GetUserRequest, ListUsersRequest, UpdateUserPasswordRequest, UpdateUserRequest } from "../api/requests/user";
import { ContactRequest } from "../api/requests/contact";
import { ActivateCompanyRequest, DeleteCompanyRequest, ListCompaniesRequest } from "../api/requests/company";


const StateLoader = (props: {children: any}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [feedback, setFeedback] = useState("");

    const [auth, _setAuth] = useRecoilState(authState);
    const [loaded, setLoaded] = useState(false);
    const [_, setAppInfo] = useRecoilState(appInfoState);
    const [profiles, setProfiles] = useRecoilState(profileState);
    const [projectList, setProjectList] = useRecoilState(projectListState);
    const [activeProject, setActiveProject] = useRecoilState(activeProjectState);

    const token = useRecoilValue(selectToken);
    const company = useRecoilValue(selectCompany);
    const user = useRecoilValue(selectUser);

    client.setLoginInfo(token ?? "", company.id, user?.id ?? "")

    const language = useLanguage();

    function handleError(error: APIException, language: Language) {
        console.error(error);
        setError(error.format(language));
        setLoading(false);
    };

    async function makeAPICall(call: () => any, showLoader?: boolean, throwError?: boolean) {
        var throwableError = undefined;
        if (showLoader ?? true) {
            setLoading(true);
        };
        try {
            const response = await call();
            return response;
        } catch (error) {
            setFeedback("");
            if (error instanceof APIException) {
                handleError(error, language);
            } else {
                setError(language.error.server.unknown());
            }
            throwableError = error;
        } finally {
            if (showLoader ?? true) {
                setLoading(false);
            };
            if ((throwError ?? false) && (throwableError !== undefined)) {
                throw throwableError;
            }
        }
    };

    const loadActiveProject = async (projectId: string) => {
        setFeedback(language.projects.loading);
        const request: LoadActiveProjectRequest = {
            company_id: company.id,
            project_id: projectId
        };
        try {
            const response = await makeAPICall(() => client.loadActiveProject(request), true, true);
            setActiveProject(response);
            if (response.parse_result.error !== null && response.parse_result.error !== undefined) {
                setFeedback("");
                setError(language.error.server.project_parsing_failed({parse_result: response.parse_result}));
            };
            return response
        } catch (e) {
            if (e instanceof APIException) {
                var parseResult;
                var parseError;
                if (e.errorCode === "project_raw_parsing_failed") {
                    parseResult = e.errorParams["raw_parse_result"]
                    parseError = ParseErrorType.INVALID_FILE;
                } else if (e.errorCode === "project_parsing_failed") {
                    parseResult = e.errorParams["parse_result"];
                    parseError = ParseErrorType.PARSING_FAILED
                }
                if (parseResult !== undefined) {
                    setActiveProject({
                        project: e.errorParams["project"],
                        parse_result: {...parseResult, page: parseResult.records, parse_error: parseError},
                        conversions: null
                    })
                }
            }
        }
        setFeedback("");
    };

    const refreshProjects = async () => {
        return await makeAPICall(() => client.getProjects({company_id: company.id}).then((response: GetProjectsResponse) => {
            setProjectList({...projectList, projects: response.projects})
        }));
    };

    const apiContext = {
        refreshProfiles: async () => {
            const getProfilesRequest: GetProfilesRequest = {
                company_id: company.id
            };
            return await makeAPICall(() => client.getProfiles(getProfilesRequest).then((response: GetProfilesResponse) => {
                setProfiles({...profiles, profileHeaders: response.profiles})
            }));
        },
        refreshProjects: async () => await refreshProjects(),
        updateProject: async (id: string, name: string, profileID: string, drawSettings: DrawSettings, csvFile: SelectedFile, tmfFile: SelectedFile, gpsFile: SelectedFile) => {
            const request: UpdateProjectRequest = {
                update: {
                    id: id,
                    company_id: company.id,
                    name: name,
                    profile_id: profileID,
                    draw_settings: drawSettings,
                    csv_file: csvFile,
                    gps_file: gpsFile,
                    tmf_file: tmfFile
                }
            };
            return await makeAPICall(
                async () => {
                    const response = await client.updateProject(request);
                    if (response !== undefined) {
                        const activeProject = await client.loadActiveProject({company_id: company.id, project_id: response.project.id});
                        setActiveProject(activeProject);
                        setFeedback("");
                    }
                }
            )
        },
        deleteProject: async (projectId: string) => {
            const request: DeleteProjectRequest = {
                company_id: company.id,
                project_id: projectId
            };
            setProjectList({...projectList, projects: projectList.projects.filter(
                (p: Project) => {return p.id !== projectId}
            )});
            return await makeAPICall(() => client.deleteProject(request).then((_: DeleteProjectResponse) => {

                makeAPICall(() => client.getProjects({company_id: company.id}).then((response: GetProjectsResponse) => {
                    setProjectList({...projectList, projects: response.projects})
                }));
            }))
        },
        createProject: async (request: CreateProjectRequest) => {
            return await makeAPICall(async () => {
                setFeedback(language.projects.creating);
                let createProjectResponse: CreateProjectResponse = await client.createProject(request);
                const activeProject = await client.loadActiveProject({company_id: company.id, project_id: createProjectResponse.project.id});
                setActiveProject(activeProject);
                setFeedback("");
                await refreshProjects();
                return createProjectResponse;
            })
        },
        createProfile: async (name: string, objectCodeLength: number, objFile: SelectedFile, dwgFile: SelectedFile) => {
            const request: CreateProfileRequest = {
                company_id: company.id,
                name: name,
                object_code_length: objectCodeLength,
                obj_file: objFile,
                dwg_file: dwgFile
            };
            const response = await makeAPICall(() => client.createProfile(request));
            return response;
        },
        copyProfile: async (profileID: string, newName: string) => {
            const request: CopyProfileRequest = {
                company_id: company.id,
                profile_id: profileID,
                new_name: newName,
            };
            const response = await makeAPICall(() => client.copyProfile(request));
            return response;
        },
        deleteProfile: async (profileID: string) => {
            const request: DeleteProfileRequest = {
                company_id: company.id,
                profile_id: profileID
            };
            const response = await makeAPICall(() => client.deleteProfile(request));
            setProfiles({profileHeaders: profiles.profileHeaders.filter(
                (p: ProfileHeader) => {return p.id !== profileID}
            )})
        },
        getProfileRecords: async (profileID: string) => {
            const request: GetProfileRecordsRequest = {
                company_id: company.id,
                profile_id: profileID
            };
            const response = await makeAPICall(() => client.getProfileRecords(request));
            return response.records;
        },
        updateProfileRecords: async (changes: Array<ProfileRecordChange>, profileID: string) => {
            const request: UpdateProfileRecordsRequest = {
                company_id: company.id,
                profile_id: profileID,
                updates: changes
            };
            const response = await makeAPICall(() => client.updateProfileRecords(request));
            return response.records;
        },
        updateProfileHeader: async (profileID: string, name: string, objectCodeLength: number, symbolsFile: SymbolsFile, objFile?: SelectedFile) => {
            console.log(objFile)
            const request: UpdateProfileHeaderRequest = {
                update: {
                    id: profileID,
                    company_id: company.id,
                    name: name,
                    object_code_length: objectCodeLength,
                    symbols_file: symbolsFile,
                    obj_file: objFile
                }
            }
            const response = await makeAPICall(() => client.updateProfileHeader(request));
            return response;
        },
        downloadFile: async (name: string, url: string) => {
            const request: DownloadFileRequest = {
                name: name,
                url: url
            };
            const response = await makeAPICall(() => client.downloadFile(request));
            return response
        },
        loadProject: async (projectId: string) => await loadActiveProject(projectId),
        updateProjectFileLines: async (projectId: string, changes: Array<ProjectFileLineChange>) => {
            const request: UpdateProjectFileLinesRequest = {
                company_id: company.id,
                project_id: projectId,
                changes: changes
            };
            const response = await makeAPICall(() => client.updateProjectRawRecords(request));
            return response;
        },
        updateRawProjectRecords: async (projectId: string, changes: Array<RawProjectRecordChange>) => {
            const request: UpdateProjectRecordsRequest = {
                company_id: company.id,
                project_id: projectId,
                changes: changes
            };
            const response = await makeAPICall(() => client.updateProjectRecords(request));

            if (response !== undefined) {
                setActiveProject({...activeProject, parse_result: response.parse_result});
            }
            return response;
        },
        getProjectRawRecords: async (projectId: string, pageNumber: number, pageSize: number) => {
            const request: GetRecordsRequest = {
                company_id: company.id,
                project_id: projectId,
                page_number: pageNumber,
                page_size: pageSize
            };
            const response = await makeAPICall(() => client.getProjectRawRecords(request));
            if (activeProject.parse_result !== null && response !== undefined) {
                const newParseResult: ParseResult = {...activeProject.parse_result, page: response.records, error: ParseErrorType.INVALID_FILE}
                setActiveProject({...activeProject, parse_result: newParseResult})
            }

            return response;
        },
        getProjectRecords: async (projectId: string, pageNumber: number, pageSize: number) => {
            const request: GetRecordsRequest = {
                company_id: company.id,
                project_id: projectId,
                page_number: pageNumber,
                page_size: pageSize
            };
            const response = await makeAPICall(() => client.getProjectRecords(request));
            if (activeProject.parse_result !== null && response !== undefined) {
                const newParseResult: ParseResult = {...activeProject.parse_result, page: response.records};
                setActiveProject({...activeProject, parse_result: newParseResult})
            }
            return response;
        },
        recalculateConversions: async (projectId: string, pointNumbers: Array<Array<string>>) => {
            const request: RecalculateConversionsRequest = {
                company_id: company.id,
                project_id: projectId,
                point_numbers: pointNumbers
            };
            const response = await makeAPICall(() => client.recalculateConversions(request));
            if (response !== undefined) {
                setActiveProject({...activeProject, conversions: response.conversions});
            }
            return response?.conversions
        },
        initiateDrawing: async (projectId: string, preview: boolean) => {
            const request: DrawRequest = {
                company_id: company.id,
                project_id: projectId,
                preview: preview
            };
            const response = await makeAPICall(() => client.initiateDrawing(request), false);
            return response;
        },
        getDrawingStatus: async (projectId: string) => {
            const request: GetDrawingStatusRequest = {
                company_id: company.id,
                project_id: projectId
            };
            const response = await makeAPICall(() => client.getDrawingStatus(request), false);
            if (response.status === DrawingStatus.FAILED) {
                setFeedback("")
                setError(language.error.server.drawing_failed)
            }
            return response;
        },
        listUsers: async () => {
            const request: ListUsersRequest = {
                company_id: company.id
            };
            const response = await makeAPICall(() => client.listUsers(request));
            return response;
        },
        deleteUser: async (userId: string) => {
            console.log("deleting user")
            const request: DeleteUserRequest = {
                company_id: company.id,
                user_id: userId
            };
            const response = await makeAPICall(() => client.deleteUser(request));
            return response;
        },
        getUser: async (userId: string) => {
            const request: GetUserRequest = {
                company_id: company.id,
                user_id: userId
            };
            const response = await makeAPICall(() => client.getUser(request));
            return response;
        },
        updateUser: async (userId: string, name: string, email: string, role: Role) => {
            const request: UpdateUserRequest = {
                user: {
                    id: userId,
                    company_id: company.id,
                    name: name,
                    email: email,
                    role: role
                }
            };
            const response = await makeAPICall(() => client.updateUser(request));
            return response;
        },
        createUser: async (name: string, email: string, role: Role, password: string) => {
            const request: CreateUserRequest = {
                user: {
                    name: name, 
                    company_id: company.id,
                    email: email,
                    role: role,
                    password: password
                }
            }
            const response = await makeAPICall(() => client.createUser(request));
            return response;
        },
        updateUserPassword: async (password: string) => {
            const request: UpdateUserPasswordRequest = {
                update: {
                    password: password
                }
            }
            const response = await makeAPICall(() => client.updateUserPassword(request));
            setFeedback(language.userLabels.passwordChanged)
            return response;
        },
        contact: async (message: string) => {
            const request: ContactRequest = {
                message
            }
            const response = await makeAPICall(() => client.contact(request));
            return response
        },
        listCompanies: async () => {
            const request: ListCompaniesRequest = {}
            const response = await makeAPICall(() => client.listCompanies(request));
            return response
        },
        activateCompany: async (companyId: string) => {
            const request: ActivateCompanyRequest = {
                company_id: companyId
            }
            const response = await makeAPICall(() => client.activateCompany(request));
            return response
        },
        deleteCompany: async (companyId: string) => {
            const request: DeleteCompanyRequest = {
                company_id: companyId
            }
            const response = await makeAPICall(() => client.deleteCompany(request));
            return response
        }
    }

    useEffect(() => {
        if (!loaded) {
            // Load version
            client.getAppInfo().then((data: AppInfoResponse) => {
                setAppInfo(data);
            })

            // Load profiles
            apiContext.refreshProfiles();
            // Load Projects
            apiContext.refreshProjects();
            
            // Set loaded
            setLoaded(true);
        }
    }, [auth, loaded]);

    return <APIStateContext.Provider value={apiContext}>
        <LinearProgress sx={{
            display: loading ? "block" : "none",
            position: "fixed",
            width: "100%",
            top: 0,
            zIndex: 2000
        }} />
        <Backdrop
            sx={{ color: '#fff', zIndex: 1999}}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        {props.children}
        <Snackbar sx={{ color: '#fff', zIndex: 2001}} open={error !== ""} autoHideDuration={20000} onClose={() => setError("")}>
            <Alert onClose={() => setError("")} severity="error" sx={{ width: '100%' }}>{error}</Alert>
        </Snackbar>
        <Snackbar sx={{ color: '#fff', zIndex: 2001}} open={feedback !== ""} onClose={() => setFeedback("")}>
            <Alert onClose={() => setFeedback("")} severity="info" sx={{ width: '100%' }}>{feedback}</Alert>
        </Snackbar>
    </APIStateContext.Provider>
};

export default StateLoader;